import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import {
  SetStatisticsDocument,
  StatisticsAddPresentedDocument,
  StatisticsAddWareItem,
  StatisticsDeleteWareItems,
  StatisticsReplacePresentedDocuments,
  StatisticsReplaceWares,
  StatisticsReplaceWaresAndDocuments,
  StatisticsUpdateBlock,
  StatisticsUpdatePresentedDocuments,
  StatisticsUpdateWareItems,
} from './statistics.actions';
import { StatisticsStateModel } from '../../../types/documents/statistics';

@State<StatisticsStateModel>({
  name: 'statistics',
  defaults: {
    selectedDocument: null,
    selectedDocumentWares: [],
  },
})
@Injectable()
export class StatisticsState {
  // TODO: сделать селекторы на каждый блок в отдельности для оптимизации
  @Selector()
  static getSelectedDocument(state: StatisticsStateModel) {
    return state.selectedDocument;
  }

  @Selector()
  static getSelectedDocumentWares(state: StatisticsStateModel) {
    return state.selectedDocumentWares;
  }

  @Action(StatisticsUpdateBlock)
  statisticsUpdateBlock(
    ctx: StateContext<StatisticsStateModel>,
    action: StatisticsUpdateBlock,
  ) {
    const [key] = Object.keys(action.block);

    ctx.setState(
      produce(draft => {
        if (draft.selectedDocument) {
          draft.selectedDocument[key] = action.block[key];
        }
      }),
    );
  }

  @Action(StatisticsAddPresentedDocument)
  statisticsAddPresentedDocument(
    ctx: StateContext<StatisticsStateModel>,
    action: StatisticsAddPresentedDocument,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.push(
          ...action.presentedDocumentItem,
        );
      }),
    );
  }

  @Action(StatisticsUpdatePresentedDocuments)
  statisticsUpdatePresentedDocuments(
    ctx: StateContext<StatisticsStateModel>,
    action: StatisticsUpdatePresentedDocuments,
  ) {
    ctx.setState(
      produce(draft => {
        action.presentedDocuments.forEach(document => {
          const id = document.id;
          const index = ctx
            .getState()
            .selectedDocument?.presentedDocuments.findIndex(i => i.id === id);
          if (index !== null && index !== undefined) {
            draft.selectedDocument?.presentedDocuments.splice(
              index,
              1,
              document,
            );
          }
        });
      }),
    );
  }

  @Action(StatisticsReplacePresentedDocuments)
  statisticsReplacePresentedDocuments(
    ctx: StateContext<StatisticsStateModel>,
    action: StatisticsReplacePresentedDocuments,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.splice(0);
        draft.selectedDocument?.presentedDocuments.push(
          ...action.presentedDocuments,
        );
      }),
    );
  }

  @Action(SetStatisticsDocument)
  setStatisticsDocument(
    ctx: StateContext<StatisticsStateModel>,
    action: SetStatisticsDocument,
  ) {
    const { wares, ...other } = action.declaration;
    ctx.patchState({
      selectedDocumentWares: wares,
      selectedDocument: other,
    });
  }

  @Action(StatisticsAddWareItem)
  statisticsAddWareItem(
    ctx: StateContext<StatisticsStateModel>,
    action: StatisticsAddWareItem,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocumentWares?.push(action.wareItem);
      }),
    );
  }

  @Action(StatisticsUpdateWareItems)
  statisticsUpdateWareItems(
    ctx: StateContext<StatisticsStateModel>,
    action: StatisticsUpdateWareItems,
  ) {
    ctx.setState(
      produce(draft => {
        action.wareItems.forEach(wareItem => {
          const id = wareItem.id;
          const index = ctx
            .getState()
            .selectedDocumentWares.findIndex(i => i.id === id);
          if (index !== null) {
            draft.selectedDocumentWares.splice(index, 1, wareItem);
          }
        });
      }),
    );
  }

  @Action(StatisticsDeleteWareItems)
  statisticsDeleteWareItems(
    ctx: StateContext<StatisticsStateModel>,
    action: StatisticsDeleteWareItems,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.splice(0);
        draft.selectedDocument?.presentedDocuments.push(
          ...action.documentsAndWares.presentedDocuments,
        );
        draft.selectedDocumentWares.splice(0);
        draft.selectedDocumentWares.push(...action.documentsAndWares.wares);
      }),
    );
  }

  @Action(StatisticsReplaceWaresAndDocuments)
  statisticsReplaceWaresAndDocuments(
    ctx: StateContext<StatisticsStateModel>,
    action: StatisticsReplaceWaresAndDocuments,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.splice(0);
        draft.selectedDocument?.presentedDocuments.push(
          ...action.documentsAndWares.presentedDocuments,
        );
        draft.selectedDocumentWares.splice(0);
        draft.selectedDocumentWares.push(...action.documentsAndWares.wares);
      }),
    );
  }

  @Action(StatisticsReplaceWares)
  statisticsReplaceWares(
    ctx: StateContext<StatisticsStateModel>,
    action: StatisticsReplaceWares,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocumentWares = action.wareItems;
      }),
    );
  }
}
